<template>
<div center class="wait">
 <v-progress-circular
        :size="60"
        :width="5"
        color="#B452DB"
        indeterminate
      ></v-progress-circular>
</div>
</template>

<script>
  export default {
    data(){
      return{
      }
    },

}
</script>

<style>
  .wait {
    max-width: 400px;
    margin-left: 38%;
    margin-top: 1%;
  }
 .v-progress-circular {
  margin: 1rem;
  vertical-align: center;
  }
 
</style>
