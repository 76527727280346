<template>
<v-container>
        <!-- check that tutorials exist for given topic and populate expansion panel -->
      <!-- pre-expansion row -->
      <v-expansion-panel v-if="filteredTopics.length">
        <v-expansion-panel-content :class="`tutorial ${tutorial.topic} `" v-for="tutorial in filteredTopics" :key="tutorial.id">
          <div slot="header" class >{{ tutorial.title }}
          </div>
      <!-- end pre-expansion row -->  
        <!-- start inner card -->  
        <!-- <v-layout class="pa-0">      -->
          <!-- left card -->
          <!-- <v-flex xs12 sm6 responsive>
              <v-card> -->
                <!-- begin video lazy-load -->
                <!-- <div class="mt-2 pt-2" v-if="vidnow == false">
                <v-img :aspect-ratio="16/9"
                  class="white--text"
                  max-height="250px"
                  max-width="445px"
                  :src="`https://i.ytimg.com/vi/${tutorial.vid_id}/hqdefault.jpg`" -->
                <!-- > -->

                <!-- position play button for lazy-loaded video -->
                <!-- <v-container fill-height fluid>
                  <v-layout fill-height justify-end align-center class="justify-center">
                    <v-flex xs3 align-center flexbox>
                      <v-btn icon large class="fade secondary error--text"><v-icon large justify-start @click="vidnow = true">play_arrow</v-icon></v-btn>
                    </v-flex>
                  </v-layout>
                </v-container> -->
                <!-- end play button for lazy-loaded video -->
                <!-- </v-img>
                </div> -->
                  <!-- <div class="videoWrapper">
                    <iframe :src="`https://www.youtube.com/embed/${tutorial.vid_id}`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                  </div> -->
                <!-- end video lazy-load -->




        <!-- <v-card-title>
          <div yellow></div>
        </v-card-title> -->

      <!-- </v-card>
         </v-flex> -->
      <!-- end of 1st card -->

      <!-- beginning of 2nd card -->
        <v-flex xs-12 sm-12>
         <v-card>

           <!-- start headline row -->
            <v-card-title primary-title class="organize">

              <!-- start color-coded topic name & icon link -->
              <router-link class="pl-0 pb-0" style="text-decoration: none;"  :to="{name: tutorial.topic }">
                  <h3 :class="`bytopic ${tutorial.topic}`">{{tutorial.topic}}
                  <v-icon light small :class="`bytopic ${tutorial.topic}`">view_list</v-icon></h3>
                  <!-- <br />
                  <br />
                  <br /> -->
              </router-link>
              <!-- end topic name & icon link -->

                <!-- start tutorial headline -->
                <div class="lower ma-0 pa-0">
                <router-link style="text-decoration: none;"  :to="{name: 'Video', params: {id: tutorial.vid_id }}">
                  <h3 class="headline ma-0 pa-0">{{tutorial.title}}</h3><v-icon large right class="down error--text ma-0 pa-0">play_arrow</v-icon>
                </router-link>
                </div>

                <!-- end tutorial headline -->

            </v-card-title>
            <!-- end headline row-->

            <!-- start description section -->
            <v-card-text class="px-4 py-1 black--text">
              <div class="ml-4">{{ tutorial.content }}</div>
              <div class="ml-4 font-weight-light">Produced by {{ tutorial.producer }}</div>
              <div class="ml-4 font-weight-light">Narrated by {{ tutorial.narrator }}</div>
            </v-card-text>

            <!-- end description section -->

            <!--start bottom links & actions -->
            <v-card-actions>
              <!-- uncomment when details & cheats buttons active 
                <div>
                   <a href="#" class="actex error--text"> 
                  DETAILS
                  <v-icon dark small class="error--text">details</v-icon>

                </a>
                <a href="#" class="actex error--text"> 
                  CHEATLIST
                  <v-icon dark small class="error--text">casino</v-icon>
                </a>
                </div>
                uncomment details & cheats buttons when activated -->
              </v-card-actions>
              <!--end bottom links & actions -->
            </v-card>
          </v-flex>
      <!-- end of 2nd card -->
        <!-- </v-layout>  -->
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- end expansion panel -->

      <!-- if there are no tutorials for the topic, user message -->
      <div v-else>
        <h2 class="secondary--text pt-1 mt-1 ml-5 font-weight-light" v-if="this.loading ==='done'">There are currently no tutorials for this topic.</h2>
      <!-- progress bar while awaiting data -->  
      <Waiting v-else />



      </div>
      <!--end load feedback section -->
</v-container>
</template>

 <script>
// import store from '@/store/store'
//import component for progress bar
import Waiting from './Waiting'
// import firestore
import db from '@/fb'
export default {
  components: { Waiting },
  props: ['category'],
  data() {
    return {
    vidnow: false,
    //initialize tutorial data array
    tutorials: [],
    //initialize variable to show/hide progress bar
    loading: ''
  }  
},

created() {

      // get firebase data & check for database changes
      db.collection('tutorials').onSnapshot(res => {
        const changes = res.docChanges();

        changes.forEach(change => {
          if (change.type === 'added'){
            this.tutorials.push({
              ...change.doc.data(),
              id: change.doc.id
            })
          }
        })
          //hide progress bar since loading is done
          this.loading = 'done'
      })
    },

beforeMount() {
  //set variable to show progress bar
  this.loading = 'loading'
},
//
  computed: {
    //filter tutorials by topic
    filteredTopics() {
          return this.tutorials.filter( tutorial => {
          return tutorial.topic.match(this.category)
      })
      } 
    },
    
    } 
</script>
<style scoped>
  .tutorials {
    background-image: url('../assets/bella_awake.png');
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    min-height: 100vh;
    min-width: 10vw;
    max-width: 100vw;
    padding: 0;
  }

.tutorial.Animation {
  border-left: 15px solid #ff00ff;
  border-bottom: 1px solid #ff00ff;
}
.tutorial.Compositing {
  border-left: 15px solid #734c7c;
  border-bottom: 1px solid #734c7c;
}
.tutorial.GameCreation {
  border-left: 15px solid #AEEA00;
  border-bottom: 1px solid #AEEA00;
}
.tutorial.Modeling {
  border-left: 15px solid orange;
  border-bottom: 1px solid orange;
}
.tutorial.MotionTracking {
  border-left: 15px solid indigo;
  border-bottom: 1px solid indigo;
}
.tutorial.Rendering {
  border-left: 15px solid red;
  border-bottom: 1px solid red;
}
.tutorial.Rigging {
  border-left: 15px solid olive;
  border-bottom: 1px solid olive;
}
.tutorial.Simulation {
  border-left: 15px solid maroon;
  border-bottom: 1px solid maroon;
}
.tutorial.VideoEditing {
  border-left: 15px solid blue;
  border-bottom: 1px solid blue;
}

.bytopic.Animation {
  color: #ff00ff;
  font-weight: 300;
}
.bytopic.Compositing {
  color: #734c7c;
  font-weight: 300;
}
.bytopic.GameCreation {
  color: #AEEA00;
  font-weight: 300;
}
.bytopic.Modeling {
  color: orange;
  font-weight: 300;
}
.bytopic.MotionTracking {
  color: indigo;
  font-weight: 300;
}
.bytopic.Rendering {
  color: red;
  font-weight: 300;
}
.bytopic.Rigging {
  color: olive;
  font-weight: 300;
}
.bytopic.Simulation {
  color: maroon;
  font-weight: 300;
}
.bytopic.VideoEditing {
  color: blue;
  font-weight: 300;
}

.headline {
  display: inline;
  flex-flow: row;
  font-size: 2em;
}
.router-link.h2 {
  font-weight: light;
}
.fade {
  opacity: .7;
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
  .v-progress-circular {
  margin: .75rem;
  }
  .down {
    position: relative;
    top: 8px;
  }
  .lower {
    position: relative;
    display: block;
  }
  .organize {
    position: relative;
    top: 0px;
    left: 35px;
    display: flex;
    flex-flow: column;
    align-items: start;
  }
</style>

